
"use strict"

/**
 * SPメニュートグル
 */

var currentWidth = 0;

$(()=>{

	// メニューボタンクリックで表示
	$('.MenuButton').on('click', ()=>{
		$('.Menu').addClass('on')
		$('html').css({overflow: 'hidden'})
		$('html').css({position: 'fixed'})
	})

	// 閉じるボタンクリックで非表示
	$('.close a').on('click', ()=>{
		$('.Menu').removeClass('on')
		$('html').css({overflow: ''})
		$('html').css({position: ''})
	})

	// 横幅変更を検出してリサイズ発火
	$(window).on('resize', ()=>{

		// 横幅更新
		if (currentWidth == $(window).outerWidth()) return;
		currentWidth = $(window).outerWidth();

		// メニュー非表示
		$('.close a').trigger('click');
	});
	$(window).resize();

});
