
"use strict"

/**
 * スムーズアンカーリンク
 */

$(()=>{

	let anchor_speed = 500;
	$('a[href^="#"]').click(function(){
		let href = $(this).attr("href")
		let target = $((href=='#' || href=='' || $(href).length==0 ) ? 'html' : href)
		let position = target.offset().top
		if( position < 0 ) { position = 0; }
		$('body,html').animate({scrollTop:position}, anchor_speed);
		return false;
	});
	$(window).on('load', function(){
		let m
		if(m=location.href.match(/#(.+)$/) ){
			if($('#'+m[1]).offset()){
				$('body,html').animate({scrollTop:$('#'+m[1]).offset().top-50}, anchor_speed);
			}
		}
	});

});
