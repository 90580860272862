
"use strict"

/**
 * 先頭にスクロールするリンクの表示/非表示
 */

$(()=>{

	$(window).on('scroll', ()=>{
		if($(window).scrollTop()>100) {
			$('.to-top').addClass('on')
		} else {
			$('.to-top').removeClass('on')
		}
	})

});
